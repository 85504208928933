import { useEffect } from 'react';
import i18n from '../i18n';
import * as CookieConsent from 'vanilla-cookieconsent';
import { fetchConsent } from 'api/requests';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const useCookieConsent = () => {
  useEffect(() => {
    const initCookieConsent = async () => {
      const translations = {
        en: await fetchConsent('en'),
        et: await fetchConsent('et'),
      };

      const cc = CookieConsent;
      cc.run({
        cookie: {
          name: 'cookie-consent',
          domain: window.location.hostname,
          path: '/',
          expiresAfterDays: 182,
          sameSite: 'Lax',
          useLocalStorage: false
        },
        mode: 'opt-in',
        hideFromBots: true,
        autoClearCookies: true,
        lazyHtmlGeneration: true,
        disablePageInteraction: true,
        guiOptions: {
          consentModal: {
            layout: 'bar',
            position: 'bottom',
            equalWeightButtons: true,
            flipButtons: false
          },
          preferencesModal: {
            layout: 'box',
            position: 'right',
            equalWeightButtons: true,
            flipButtons: false
          }
        },
        categories: {
          necessary: { readOnly: true },
          analytics: {
            autoClear: {
              cookies: [
                { name: /^(_ga|_gid|_fbp)/ },
              ],
            },
          },
          marketing: {},
        },
        language: {
          default: 'et',
          translations,
          autoDetect: 'document',
        },
        onFirstConsent: () => updateConsent(cc),
        onConsent: () => updateConsent(cc),
        onChange: () => updateConsent(cc),
      });
    }

    function updateConsent (cc) {
      if (typeof gtag === 'function') {
        // eslint-disable-next-line no-undef
        gtag('consent', 'update', {
          'ad_storage': cc.acceptedCategory('marketing') ? 'granted' : 'denied',
          'ad_user_data': cc.acceptedCategory('marketing') ? 'granted' : 'denied',
          'ad_personalization': cc.acceptedCategory('marketing') ? 'granted' : 'denied',
          'analytics_storage': cc.acceptedCategory('analytics') ? 'granted' : 'denied',
          'functionality_storage': cc.acceptedCategory('necessary') ? 'granted' : 'denied',
          'personalization_storage': cc.acceptedCategory('necessary') ? 'granted' : 'denied',
        });
      }

      if (typeof fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('consent', cc.acceptedCategory('analytics') ? 'grant' : 'revoke');
      }

      document.body.style.overflow = 'auto';
    }

    function checkCookie(cookieName) {
      return document.cookie.split('; ').some(cookie => cookie.startsWith(cookieName + '='));
    }

    initCookieConsent();

    if (checkCookie('cookie-consent')) {
      document.body.style.overflow = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
};

export default useCookieConsent;

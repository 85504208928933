import React, { useEffect, useState } from "react";
import { Image, Button } from "ui";
import { useSelector } from "react-redux";
import Slider from "components/Slider";

const PopularAccessoryCategories = (props) => {
  const {
    className,
    content,
    onlySlider = false,
    lightMode = false,
    key,
    bulletColor = 'default',
  } = props;
  const { locale } = useSelector((state) => state.i18n);
  const classes = `popular-accessory-categories-section${
    className ? " " + className : ""
  }${lightMode ? " light-mode" : ""}`;

  const heroButtonLink = content.hero_button?.[locale] ? content.hero_button[locale] : null;

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const slides = content.accessory_categories?.map(
      accessory_category => (accessory_category && <>
        <Image
          className="popular-accessory-categories__point-image"
          content={accessory_category.image}
        />
        {accessory_category.name && accessory_category.slug && (
          <Button
            className="popular-accessory-categories__point-button"
            text={accessory_category.name}
            styleType="white"
            to={accessory_category.slug}
          />
        )}
      </>),
    );

    if (content.last_item_image) {
      slides.push(<>
        <Image
          className="popular-accessory-categories__point-image"
          content={content.last_item_image}
        />
        {content.last_item_text && content.last_item_link} && (
          <Button
            className="popular-accessory-categories__point-button"
            text={content.last_item_text}
            styleType="white"
            to={content.last_item_link}
          />
        )
      </>
      );
    }
    setSlides(slides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section key={key ? key : Math.random()} className={classes}>
      <div className="container">
        <div className="popular-accessory-categories">
          {!onlySlider && (
            <div className="popular-accessory-categories__image-wrapper">
              <Image
                className="popular-accessory-categories__image"
                content={content.image}
              />
              {content.banner_title && (
                <h2 className="popular-accessory-categories__image-title">
                  {content.banner_title}
                </h2>
              )}
              {(content.primary_button || content.secondary_button) && (
                <div className="popular-accessory-categories__buttons-wrapper">
                  {content.primary_button && (
                    <Button
                      text={content.primary_button_text}
                      to={content.primary_button[locale]}
                    />
                  )}
                  {content.secondary_button && (
                    <Button
                      text={content.secondary_button_text}
                      styleType="transparent"
                      to={content.secondary_button[locale]}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          <div className="popular-accessory-categories__block">
            {content.title && (
              <h2 className="popular-accessory-categories__block-title">
                {content.title}
              </h2>
            )}
            {slides?.length > 0 && (
              <Slider className="popular-accessory-categories__list-wrap" bulletColor={bulletColor} slides={slides}></Slider>
            )}

            {heroButtonLink && (
              <Button
                text={content.hero_button_text}
                className="popular-accessory-categories__block-button"
                to={heroButtonLink}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularAccessoryCategories;
